import React from "react";

function SvgWebdevelopment(props) {
  return (
    <svg viewBox="0 0 127 123.031" {...props}>
      <path
        d="M121.047 0H5.953A5.953 5.953 0 000 5.953v87.313a1.984 1.984 0 001.984 1.984h45.2l-3.175 15.875h-4.321a1.984 1.984 0 00-1.984 1.984v5.953h-3.97v3.969h59.532v-3.969H89.3v-5.953a1.984 1.984 0 00-1.984-1.984h-4.333L79.808 95.25h45.207A1.984 1.984 0 00127 93.266V5.953A5.953 5.953 0 00121.047 0zM81.359 115.094h3.969v3.969H41.672v-3.969h39.687zm-33.3-3.969l3.172-15.875h24.53l3.175 15.875zm74.97-19.844H3.969V73.422h119.062zM95.25 17.859H79.375a1.984 1.984 0 00-1.984 1.984V37.7h3.969V21.828h11.906v7.938h-7.938v3.969h7.938v9.922h-7.938v3.969h7.938v9.922h-7.938v3.969h7.938v7.938H81.359V53.578h-3.968v15.875h-7.938v-9.922c0-8.1 9.835-9.878 10.247-9.95a1.984 1.984 0 001.375-2.977L63.216 16.839a2.062 2.062 0 00-3.4 0l-17.86 29.766a1.984 1.984 0 001.369 2.977c.419.071 10.253 1.851 10.253 9.95v9.922h-7.937V53.578h-3.969v15.875h-1.984V33.734h1.984V37.7h3.969v-5.95a1.962 1.962 0 00-.161-.782l-5.953-13.89a1.984 1.984 0 00-3.647 0l-5.954 13.89a1.962 1.962 0 00-.161.782v37.7H13.891V13.891h99.219v55.562H97.234V19.844a1.984 1.984 0 00-1.984-1.985zM57.547 63.5v-1.984h7.938V63.5zm7.938 3.969v1.984h-7.938v-1.984zM57.4 57.547c-.951-6.31-6.354-9.723-10.676-11.182l12.807-21.341v16.648H63.5V25.024l12.805 21.341c-4.326 1.449-9.725 4.872-10.676 11.182zM34.755 29.766l2.945-6.878 2.948 6.878zm.964 3.969v35.718h-1.985V33.734zm87.313 35.719h-5.953V11.906a1.984 1.984 0 00-1.984-1.984H11.906a1.984 1.984 0 00-1.984 1.984v57.547H3.969v-63.5a1.984 1.984 0 011.984-1.984h115.094a1.984 1.984 0 011.984 1.984z"
        className='a'
      />
      <path
        d="M63.5 75.406a5.953 5.953 0 105.953 5.953 5.953 5.953 0 00-5.953-5.953zm0 7.938a1.984 1.984 0 111.984-1.984 1.984 1.984 0 01-1.984 1.984z"
        className='b'
      />
    </svg>
  );
}

export default SvgWebdevelopment;