import React from "react";

function SvgDevOps(props) {
  return (
    <svg viewBox="0 0 127 127.467" {...props}>
      <path
        className="a"
        d="M112.133 31.89a15.11 15.11 0 00-4.569.7 27.147 27.147 0 00.212-3.358A29.735 29.735 0 0050.43 18.709a18.308 18.308 0 00-27.953 15.214 15.169 15.169 0 00-7.61-2.033 14.867 14.867 0 100 29.735 2.124 2.124 0 000-4.248A10.62 10.62 0 1124 41.059a2.124 2.124 0 003.747-1.959 13.486 13.486 0 01-1.026-5.086 13.961 13.961 0 0114.08-13.806 14.186 14.186 0 019.313 3.534 2.124 2.124 0 003.443-1.034 25.476 25.476 0 0149.957 6.527 24.637 24.637 0 01-.924 6.584 2.124 2.124 0 003.264 2.309 10.89 10.89 0 016.278-1.99 10.62 10.62 0 110 21.239 2.124 2.124 0 000 4.248 14.867 14.867 0 100-29.735zM99.601 80.741H27.388a6.372 6.372 0 00-6.372 6.372v8.5a6.372 6.372 0 006.372 6.372h72.213a6.372 6.372 0 006.376-6.377v-8.5a6.372 6.372 0 00-6.376-6.367zm2.124 14.867a2.124 2.124 0 01-2.124 2.124H27.388a2.124 2.124 0 01-2.124-2.124v-8.5a2.124 2.124 0 012.124-2.124h72.213a2.124 2.124 0 012.124 2.124z"
      />
      <path
        className="b"
        d="M93.71 87.244a4.116 4.116 0 104.142 4.09 4.116 4.116 0 00-4.142-4.09zm.051 4.25c-.064 0-.106-.07-.106-.134l.106-.132z"
      />
      <path
        className="a"
        d="M71.565 89.236H31.211a2.124 2.124 0 100 4.248h40.355a2.124 2.124 0 100-4.248z"
      />
      <path
        className="c"
        d="M84.681 89.236h-5.65a2.124 2.124 0 100 4.248h5.65a2.124 2.124 0 100-4.248z"
      />
      <path
        className="a"
        d="M99.601 106.228H27.388a6.372 6.372 0 00-6.372 6.372v8.5a6.372 6.372 0 006.372 6.372h72.213a6.372 6.372 0 006.376-6.377v-8.5a6.372 6.372 0 00-6.376-6.367zm2.124 14.867a2.124 2.124 0 01-2.124 2.124H27.388a2.124 2.124 0 01-2.124-2.124v-8.5a2.124 2.124 0 012.124-2.124h72.213a2.124 2.124 0 012.124 2.124z"
      />
      <path
        className="c"
        d="M93.71 112.731a4.116 4.116 0 104.142 4.09 4.116 4.116 0 00-4.142-4.09zm.051 4.25c-.064 0-.106-.07-.106-.134l.106-.132zM71.565 114.723H31.211a2.124 2.124 0 100 4.248h40.355a2.124 2.124 0 100-4.248z"
      />
      <path
        className="a"
        d="M84.681 114.723h-5.65a2.124 2.124 0 100 4.248h5.65a2.124 2.124 0 100-4.248zM99.601 55.254H27.388a6.372 6.372 0 00-6.372 6.372v8.5a6.372 6.372 0 006.372 6.372h72.213a6.372 6.372 0 006.376-6.377v-8.5a6.372 6.372 0 00-6.376-6.367zm2.124 14.867a2.124 2.124 0 01-2.124 2.124H27.388a2.124 2.124 0 01-2.124-2.124v-8.5a2.124 2.124 0 012.124-2.124h72.213a2.124 2.124 0 012.124 2.124z"
      />
      <path
        className="c"
        d="M97.184 65.297a4.152 4.152 0 00-7.474-1.659 4.064 4.064 0 00-.684 3.069 4.157 4.157 0 004.086 3.417 4.259 4.259 0 00.692-.057 4.1 4.1 0 002.7-1.7 4.049 4.049 0 00.68-3.07zm-4.18.748l.1-.168.011.266c-.1-.005-.106-.058-.11-.103z"
      />
      <path
        className="b"
        d="M71.14 63.749H30.786a2.124 2.124 0 100 4.248h40.355a2.124 2.124 0 000-4.248z"
      />
      <path
        className="a"
        d="M83.324 63.749h-5.65a2.124 2.124 0 100 4.248h5.65a2.124 2.124 0 100-4.248zM54.377 34.636l-6.372-6.372a2.124 2.124 0 00-3 0l-6.372 6.372a2.124 2.124 0 003 3l2.746-2.746v13.992a2.124 2.124 0 104.248 0V34.893l2.746 2.746a2.124 2.124 0 003-3zM88.307 41.008a2.124 2.124 0 00-2.951 0l-2.746 2.746V29.766a2.124 2.124 0 10-4.248 0v13.988l-2.746-2.746a2.124 2.124 0 00-3 3l6.372 6.372a2.124 2.124 0 003 0l6.372-6.372a2.124 2.124 0 00-.053-3z"
      />
    </svg>
  );
}

export default SvgDevOps;