import React from "react"

import Layout from "../components/ui/Layout"
import SEO from "../components/seo"
import Hero from '../components/ui/Hero'
import Services from '../components/ui/Services'
import ReasonList from '../components/ui/ReasonList'

const NotFoundPage = () => (
  <Layout>
    <SEO title="corenovo" />
    <Hero
      category='Bienvenido'
      mainText='Maximiza tu'
      words={['Productividad', 'Efectividad', 'Experiencia']}
      desc='con un equipo de pensadores, programadores y realizadores resolviendo ttus sueños con tecnología del mañana.'
      buttonText='contratanos ahora'
      buttonLink=''
    />
    <Services
      category='servicios'
      title='Nuestra Pasión'
      desc='Simplificamos procesos, no funcionalidades. Minificamos interfaces, no caracteristicas. Damos forma nuevas tecnologías y optimizamos el rendimiento. Buscamos definir tendencias en aplicaciones web y empresariales.'
      list={[
        {
          icon: 'webdevelopment',
          name: 'Desarrollo Web',
          excerpt: 'Construimos aplicaciones web saludables, flexibles, escalables y efectivas.',
          link: '/servicios/web'
        },
        {
          icon: 'mobiledevelopment',
          name: 'Desarrollo Móvil',
          excerpt: 'Construimos aplicaciones móviles a travez de varias plataformas con una inigualable ventaja de costo.',
          link: '/servicios/movil'
        },
        {
          icon: 'devops',
          name: 'DevOps',
          excerpt: 'Grandes aplicaciones empresariales con el poder de la nube. Rápido, sencillo y escalable.',
          link: '/servicios/devops'
        }
      ]}
    />
    <ReasonList 
      category='¿Por qué escogernos?'
      title='Tecnología trabajando para tu negocio.'
      desc='En Corenovo realizamos aplicaciones de alta calidad. Entregamos una gran variedad de proyectos y servicios a compañias de todos los tamaños. Creamos relaciones basadas en la confianza, en compartir abiertamente nuestro método de trabajo y las reglas en las cuales confiamos.'
      list={[
        {
          number: 10,
          concept: 'empleados',
          desc: 'Hombres y mujeres comprometidos por hacer tecnología de negocio.'
        },
        {
          number: 100,
          concept: 'Proyectos',
          desc: 'Donde vas a encontrar la misma calidad de servicio y dedicación alrededor del mundo.'
        },
        {
          number: 70,
          concept: 'Clientes',
          desc: 'Los cuales ven en nuestros servicios una forma de enfocar sus esfuerzos conectados al mundo del mañana.'
        }
      ]}
    />
  </Layout> 
)

export default NotFoundPage