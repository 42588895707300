import React from 'react'
import Fade from 'react-reveal/Fade'
import styled from '@emotion/styled'

const CategoryContainer = styled.div`
  padding: 0 3rem;
`

const CategoryDescription = ({ category, title, desc }) => {
  return (
    <Fade>
      <CategoryContainer>
        <h4>{category}</h4>
        <h2>{title}</h2>
        <p>{desc}</p>
      </CategoryContainer>      
    </Fade>
  );
}
 
export default CategoryDescription;