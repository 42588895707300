import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby';
import CategoryDescription from './CategoryDescription'
import DevOpsIcon from '../icons/DevOps'
import WebDevelopmentIcon from '../icons/WebDevelopment'
import MobileDevelopmentIcon from '../icons/MobileDevelopment'

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4rem 0 6rem 0;
  padding: 0 3rem;
`
const CardItem = styled.div`
  border-radius: 1rem;
  box-shadow: 1rem 2rem 6rem rgba(13, 52, 79, .2);
  display: flex;  
  justify-content: center;
  margin-bottom: 3rem;  
  transition: all .2s ease-out;
  width: 100%;
  a{
    align-items: center;
    color: #8A8B8B;
    display: flex;
    flex-direction: column;
    padding: 4rem 3rem;
  }
  svg{
    margin-bottom: 2rem;
    max-height: 10rem;
    max-width: 10rem;
    path.a{
      fill: #0B0618;
    }
    path.b{
      fill: #5E20E4;
    }
    path.c, circle.c{
      fill: #1BFDBF;
    }
  }
  h3{
    font-weight: 400;
    margin-bottom: 1.5rem;
    &:after{
      color: #1BFDBF;
      content: '.';
    }
  }
  p{
    font-size: 1.4rem;
    line-height: 2.1rem;
    margin: 0;
    text-align: center;
  }
  &:hover{
    background: #0B0618;
    box-shadow: 1rem 2rem 6rem rgba( 27, 253, 191, .2);
    a{
      color: #fff;
    }
    h3{
      color: #1BFDBF;
      &:after{
        color: #FFDA44;
      }
    }
    svg {
      path.a{
        fill: #FFF;
      }
      path.b{
        fill: #1BFDBF;
      }
      path.c, circle.c{
        fill: #FFDA44;
      }
    }
  }
  
`

const ServiceCards = ({ category, title, desc, list }) => { 
  const renderIcon = icon => {
    switch (icon) {
      case 'devops':
        return <DevOpsIcon />
      case 'webdevelopment':
        return <WebDevelopmentIcon />
      case 'mobiledevelopment':
        return <MobileDevelopmentIcon />
      default:
        return <p>No Icon</p>
    }
  }

  return ( 
    <div id="services">
      <CategoryDescription 
        category={category}
        title={title}
        desc={desc}
      />
      <CardsContainer>
        {
          list && list.map((item, i) => (
              <Fade key={`${item.name}-${i}`}>
                <CardItem>
                  <Link to={item.link}>
                    { renderIcon(item.icon) }
                    <h3>{ item.name }</h3>
                    <p>{item.excerpt}</p>
                  </Link>
                </CardItem>
              </Fade>
              
          ))
        }
      </CardsContainer>
    </div>
   );
}
 
export default ServiceCards;