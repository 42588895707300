import * as React from "react";

function SvgMobiledevelopment(props) {
  return (
    <svg
      id="mobiledevelopment_svg__Capa_1"
      viewBox="0 0 127 127"
      {...props}
    >
      <path
        className="c"
        d="M88.3 0H23.8c-1 0-1.9.8-1.9 1.9v10.9c0 1 .8 1.9 1.9 1.9h64.5c1 0 1.9-.8 1.9-1.9V1.9c0-1.1-.9-1.9-1.9-1.9zm-1.9 10.9H25.7V3.7h60.8l-.1 7.2z"
        transform="translate(7.441 27.905)"
      />
      <path
        className="c"
        d="M56.1 0H23.8c-1 0-1.9.8-1.9 1.9v21.5c0 1 .8 1.9 1.9 1.9H56c1 0 1.9-.8 1.9-1.9V1.9c0-1.1-.8-1.9-1.8-1.9zm-1.9 21.5H25.7V3.7h28.5v17.8z"
        transform="translate(7.441 46.004)"
      />
      <path
        className="b"
        d="M56.1 0H23.8c-1 0-1.9.8-1.9 1.9v21.5c0 1 .8 1.9 1.9 1.9H56c1 0 1.9-.8 1.9-1.9V1.9c0-1.1-.8-1.9-1.8-1.9zm-1.9 21.5H25.7V3.7h28.5v17.8z"
        transform="translate(7.441 74.073)"
      />
      <path
        className="b"
        d="M47.6 0H23.8c-1 0-1.9.8-1.9 1.9v49.6c0 1 .8 1.9 1.9 1.9h23.8c1 0 1.9-.8 1.9-1.9V1.9c0-1.1-.8-1.9-1.9-1.9zm-1.8 49.6H25.7V3.7h20.1v45.9z"
        transform="translate(48.121 46.013)"
      />
      <path
        className="a"
        d="M103.2 71.5c-1 0-1.9.8-1.9 1.9v5.1H25.7V1.9c0-1-.8-1.9-1.9-1.9-1 0-1.9.8-1.9 1.9v88.5c0 3.8 3.1 6.8 6.8 6.8h69.5c3.8 0 6.8-3.1 6.8-6.8v-17c.1-1-.8-1.9-1.8-1.9zm-1.9 18.8c0 1.7-1.4 3.1-3.1 3.1H28.8c-1.7 0-3.1-1.4-3.1-3.1v-8.1h75.7v8.1z"
        transform="translate(0 29.828)"
      />
      <path
        className="b"
        d="M35.2 0H23.8c-1 0-1.9.8-1.9 1.9s.8 1.9 1.9 1.9h11.4c1 0 1.9-.8 1.9-1.9S36.2 0 35.2 0z"
        transform="translate(33.982 7.441)"
      />
      <circle
        className="c"
        cx={23.8}
        cy={1.9}
        r={1.9}
        transform="translate(27.422 7.441)"
      />
      <path
        className="c"
        d="M64.5 0H23.8c-1 0-1.9.8-1.9 1.9s.8 1.9 1.9 1.9h40.7c1 0 1.9-.8 1.9-1.9S65.5 0 64.5 0z"
        transform="translate(19.348 115.838)"
      />
      <path
        className="a"
        d="M98.2 0H28.8C25 0 22 3.1 22 6.8v16.9c0 1 .8 1.9 1.9 1.9 1 0 1.9-.8 1.9-1.9v-5.1h61.8c1 0 1.9-.8 1.9-1.9 0-1-.8-1.9-1.9-1.9H25.7v-8c0-1.7 1.4-3.1 3.1-3.1h69.5c1.7 0 3.1 1.4 3.1 3.1v8.1h-6c-1 0-1.9.8-1.9 1.9 0 1 .8 1.9 1.9 1.9h6v76.6c0 1 .8 1.9 1.9 1.9 1 0 1.9-.8 1.9-1.9V6.8C105 3.1 102 0 98.2 0z"
      />
    </svg>
  );
}

export default SvgMobiledevelopment;