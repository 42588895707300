import React from 'react'
import styled from '@emotion/styled'
import ReactRotatingText from 'react-rotating-text'
import Button from './Button'

const HeroContainer = styled.div`
  padding: 6rem 3rem;
`
const MainText = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  p,
  span{
    color: #0B0618;
    font-family: 'Nunito', sans-serif;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
  }
  span{
    color: #5E20E4;
    &.react-rotating-text-cursor{
      color: #1BFDBF;
    }
  }
`
const Description = styled.div`
  color: #3B3663;
  font-size: 1.8rem;
  line-height: 2.7rem;
  width: 100%;
`


const Hero = ({ category, mainText, words, desc, buttonText, buttonLink }) => {
  return (
    <HeroContainer>
      <h4>{category}</h4>
      <MainText>
        <p>{mainText}</p>
        <span>
          <ReactRotatingText             
            items={words} 
            emptyPause ={0}
          />
        </span>
      </MainText>
      <Description>
        {desc}
      </Description>      
      <Button><a href={buttonLink}>{buttonText}</a></Button>      
    </HeroContainer>    
  );
}
 
export default Hero;