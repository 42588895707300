import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import CategoryDescription from './CategoryDescription'

const ReasonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;  
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  padding: 0 3rem;
`

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`
const Number = styled.p`
  color: #3B3663;
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 6rem;
  margin: 0;
  position: relative;
  & span{
    position: relative;
    &:after{
      color: #1BFDBF;
      content: '+';
      font-size: 2.5rem;
      position: absolute;
      right: -1.5rem;
      top: -30%;
    }
  }
`
const Concept = styled.p`
  color: #3B3663;
  font-family: 'Nunito', sans-serif;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  margin: 0;
  text-transform: uppercase;
`
const Description = styled.p`
  margin: 0;
`

const Reasons = ({ category, title, desc, list }) => {
  return (
    <ReasonsContainer>
      <CategoryDescription 
        category={category}
        title={title}
        desc={desc}
      />      
      {
        list.length > 0 && (
          <ListContainer>
            {
              list.map((item, i) => (
                <Fade key={`${item.concept}-${i}`}>
                  <ListItem>
                    <Number><span>{item.number}</span></Number>
                    <Concept>{item.concept}</Concept>
                    <Description>{item.desc}</Description>
                  </ListItem>
                </Fade>                
              ))
            }
          </ListContainer>
        )
      }      
    </ReasonsContainer>
  );
}
 
export default Reasons;